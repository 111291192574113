@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,700;1,600;1,700&family=Oswald:wght@400;600&display=swap);
/* 
font-family: 'Exo 2', sans-serif;
font-family: 'Oswald', sans-serif; 
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Exo 2',Arial, Helvetica, Sans-Serif;
  font-size: 16px;
}

.slick-slide div {
	outline: none;
}

